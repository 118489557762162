import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import SideLink from "./components/common/SideLink";
import { Suspense, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "./app/store";
import Profile from "./components/common/Profile";
import ListCounterUnRead from "./components/inbox/ListCountUnread";
import Icon from "./components/common/Icon";
import { hasPermission } from "./utils/hasPermission";
// import Wizard from "./components/common/wizard/Wizard";
// import Icon from "./components/common/Icon";

const Layout = () => {
  const [hamburgerMenu, setHamburgerMenu] = useState(true);
  // const boxRef = useRef<HTMLDivElement>(null);
  // const handleClickOutside = (event: MouseEvent) => {
  //   if (boxRef.current && !boxRef.current.contains(event.target as Node)) {
  //     setHamburgerMenu(false);
  //   }
  // };
  // useEffect(() => {
  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, []);
  const location = useLocation();
  useEffect(() => {
    const title = location.pathname.split("/")[1];
    const postitle = location.pathname.split("/")[2];

    if (title) {
      document.title = `${title[0].toUpperCase() + title.slice(1)}`;
      if (postitle && postitle.length <= 17 && postitle.length > 3) {
        document.title = `${postitle[0]?.toUpperCase() + postitle?.slice(1)}`;
      }
    } else {
      document.title = `Prima CRM`;
    }
  }, [location]);
  useEffect(() => {
    return () => {
      document.title = "Prima CRM";
    };
  }, []);
  const navigate = useNavigate();
  const token = useSelector((state: RootState) => state.login.token);
  const permissions = useSelector(
    (state: RootState) => state.login.permissions
  );
  if (!token) {
    return <Navigate to="/login" />;
  }
  return (
    <div className="w-full h-screen flex flex-col">
      <div className="flex flex-1">
        <div
          className={`w-56 flex-shrink-0 flex-col bg-[#102448] pt-7 ${
            hamburgerMenu ? "hidden md:flex" : "md:hidden flex"
          }`}
        >
          <div className="w-full grow-0">
            <img className="mx-auto" src="/logo.webp" alt="" />
          </div>
          <div className="w-full items-start justify-end flex flex-1">
            <ul className="flex flex-wrap justify-end content-center mt-20">
              <SideLink
                onClick={() => {
                  //setHamburgerMenu(false);
                }}
                href="/inbox"
                text="Inbox"
                icon="Inbox"
                isActive={location.pathname.startsWith("/inbox")}
                isNew={false}
              />
              {hasPermission(permissions, "Read", "reports") && (
                <SideLink
                  onClick={() => {
                    //setHamburgerMenu(false);
                  }}
                  href="/dashboard"
                  text="Dashboard"
                  icon="Dashboard"
                  isActive={location.pathname.startsWith("/dashboard")}
                  isNew={false}
                />
              )}
              {hasPermission(permissions, "Read", "property") && (
                <SideLink
                  onClick={() => {
                    //setHamburgerMenu(false);
                  }}
                  href="/properties"
                  text="Properties"
                  icon="Properties"
                  toggleIcon="add"
                  isActive={location.pathname.startsWith("/properties")}
                  isNew={false}
                />
              )}
              {hasPermission(permissions, "Read", "enquiry") && (
                <SideLink
                  onClick={() => {
                    //setHamburgerMenu(false);
                  }}
                  href="/enquiries"
                  text="Enquiries"
                  icon="Enquiries"
                  toggleIcon="add"
                  isActive={location.pathname.startsWith("/enquiries")}
                  isNew={false}
                />
              )}
              {hasPermission(permissions, "Read", "customer") && (
                <SideLink
                  onClick={() => {
                    //setHamburgerMenu(false);
                  }}
                  href="/customers"
                  text="Customers"
                  icon="Customers"
                  toggleIcon="add"
                  isActive={location.pathname.startsWith("/customers")}
                  isNew={false}
                />
              )}
              {hasPermission(permissions, "Read", "lead") && (
                <SideLink
                  onClick={() => {
                    //setHamburgerMenu(false);
                  }}
                  href="/leads"
                  text="Leads"
                  icon="Leads"
                  isActive={location.pathname.startsWith("/leads")}
                  isNew={false}
                />
              )}
              {hasPermission(permissions, "Read", "deal") && (
                <SideLink
                  onClick={() => {
                    //setHamburgerMenu(false);
                  }}
                  href="/deals"
                  text="Deals"
                  icon="Deals"
                  toggleIcon="add"
                  isActive={location.pathname.startsWith("/deals")}
                  isNew
                />
              )}
              {(hasPermission(permissions, "Add", "user") ||
                hasPermission(permissions, "Read", "role")) && (
                <SideLink
                  onClick={() => {
                    //setHamburgerMenu(false);
                  }}
                  href={
                    hasPermission(permissions, "Add", "user")
                      ? "/settings/users"
                      : "/settings"
                  }
                  text="Settings"
                  icon="Settings"
                  isActive={location.pathname.startsWith("/settings/users")}
                  isNew={false}
                />
              )}

              {hasPermission(permissions, "Read", "user activity") && (
                <SideLink
                  onClick={() => {
                    //setHamburgerMenu(false);
                  }}
                  href="/reports"
                  text="Reports"
                  icon="Reports"
                  toggleIcon="add"
                  isActive={location.pathname.startsWith("/reports")}
                  isNew={false}
                />
              )}
            </ul>
          </div>
        </div>

        <div className="px-1 lg:px-7 pt-3 flex-1 flex-grow w-1">
          <div className="w-full flex items-center p-3 lg:px-3 lg:py-1 rounded-lg shadow-md relative border border-dark-blue/10">
            <div
              className="w-12 cursor-pointer flex justify-center opacity-50 hover:opacity-100 transition-opacity"
              onClick={() => {
                setHamburgerMenu((cur) => !cur);
              }}
            >
              <Icon height={25} width={25} iconUrl="/icons/HambergerMenu.svg" />
            </div>
            <div className="grow-[3] flex items-center pl-4">
              <div className="flex-1 "></div>
              <div className="flex items-center gap-3">
                {/* {hamburgerMenu ? (
                  <span className="h-12"></span>
                ) : (
                  <div className="">
                    <Profile />
                  </div>
                )} */}
                <span className="relative items-center">
                  <span className="flex items-center">
                    <span
                      onClick={() => {
                        navigate("/inbox");
                      }}
                      className="cursor-pointer opacity-50 hover:opacity-100 transition-opacity"
                    >
                      <Icon
                        height={25}
                        width={25}
                        iconUrl="/icons/Notification.svg"
                      />
                    </span>
                  </span>
                  <ListCounterUnRead />
                </span>
                <Profile />
                {/* <span
                  className={`flex items-center text-[#102448] px-2 hover:bg-[#102448]/[0.20] rounded cursor-pointer ${
                    hamburgerMenu ? "bg-[#102448]/[0.20]" : "bg-transparent"
                  }`}
                  onClick={() => {
                    setHamburgerMenu(!hamburgerMenu);
                  }}
                >
                  
                </span> */}
              </div>
              {/* <div
                ref={boxRef}
                className={`absolute right-0 top-[90%] w-fit z-20 flex flex-col gap-2 rounded p-3 bg-white shadow-[0_1px_10px_-1px_rgba(0,0,0,0.1)]    transition-all ${
                  hamburgerMenu ? `flex` : `hidden`
                }`}
              >
                <div className="flex gap-1 justify-center items-center ">
                  <ProfileResponsive />
                </div>
                <div className="flex justify-center">
                  <button
                    onClick={async () => {
                      localStorage.removeItem("token");
                      await queryClient.removeQueries();
                      dispatch(setToken(null));
                    }}
                    className="bg-[#102448] text-sm text-white rounded-md p-2"
                  >
                    Logout
                  </button>
                </div>
              </div> */}
            </div>
          </div>
          <div className="flex-1">
            <Suspense fallback={<div></div>}>
              <Outlet />
            </Suspense>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
